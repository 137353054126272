import { textColorDull } from "../../styles/variables";

export const readingTime = {
    marginLeft: "1rem",
};

export const time = {
    color: textColorDull,
    paddingLeft: "0.35rem",
};
